<template>
  <div class="container-fluid">
    <section class="section mt-2 section--shadow-0">
      <div class="section__controls">
        <div v-if="app.status === 'CONFIRMED'" class="section__controls-group">
          <a v-if="hasPermission('application.profile.addTrackingStatus')" href="javascript:void(0)" class="btn btn-info" @click="openModal">
            {{ getTranslation('addTrackingStatus') }}
          </a>
        </div>
      </div>
      <div class="section__body">
        <div class="table-responsive table-3-wrapper">
          <table class="table table-bordered table-hover">
            <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">{{ getTranslation('airport') }}</th>
              <th scope="col">{{ getTranslation('date') }}</th>
              <th scope="col">{{ getTranslation('status') }}</th>
              <th scope="col">{{ getTranslation('comment') }}</th>
              <th scope="col">{{ getTranslation('action') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in tracking" v-bind:key="item.id">
              <th scope="row">{{ item.id }}</th>
              <td>{{ item.airport.fullText }}</td>
              <td>{{ item.date }}</td>
              <td>{{ item.status.title }}</td>
              <td>{{ item.comment }}</td>
              <td>

              </td>
            </tr>
            <tr v-if="tracking.length < 1">
              <td colspan="6">
                <span v-if="app.client_status === 'CONFIRMED'" class="empty">{{ getTranslation('notAddedYet') }}</span>
                <span v-else class="empty font-weight-bold text-dark">{{ getTranslation('clientHasNotConfirmed') }}</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>

    <modal
        size="lg"
        :title="getTranslation('addTrackingStatus')"
        :show.sync="showModal"
        :centered="false"
        @modalCancel="closeModal(true)"
        cancel-button-label="close"
        ok-button-label="save"
        @clickOK="saveTrackingStatus"
    >
      <div class="container-fluid">
        <form method="post">
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <div class="form-group">
                <label class="form__label">{{ getTranslation('status') }} <span class="required">*</span></label>
                <v-select
                    :options="statuses"
                    label="fullText"
                    :class="{'is-invalid':$v.form.status_id.$error}"
                    :reduce="item=>item.id"
                    v-model="form.status_id"
                />
                <div v-if="$v.form.status_id.$error" class="invalid-feedback">{{ getTranslation('requiredField') }}</div>
              </div>

              <AirportSelect ref="airportSelect" required v-model="form.airport_id"/>

              <div class="form-group">
                <label class="form__label form__label--block">{{ getTranslation('date') }}<span
                    class="required">*</span></label>
                <DatePicker
                    v-model="form.date"
                   valueType="format"
                    type="datetime"
                    format="YYYY-MM-DD HH:mm"
                    :show-second="false"
                />

                <div v-if="$v.form.date.$error" class="invalid-feedback d-block">{{ getTranslation('requiredField') }}</div>

              </div>
              <div class="form__group">
                <label class="form__label   form__label--block" for="shortArea">{{ getTranslation('comment') }}</label>
                <textarea v-model.trim="form.comment" class="field  field--full-width  field--short-area" name="" id="shortArea" cols="30" rows="10"/>
              </div>
            </div>
          </div>
        </form>
      </div>

    </modal>

  </div>
</template>

<script>
import required from "vuelidate/lib/validators/required";
import AirportSelect from "../../../../components/shared/AirportSelect";
import moment from "moment";

export default {
  name: "ApplicationTrackingComponent",
  components: { AirportSelect },
  props: {
    active: {
      default: false
    },
    appId: {
      required: true
    },
    app: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showModal: false,
      statuses: [],
      tracking: [],
      form: {
        id: null,
        status_id: '',
        app_id: this.appId,
        comment: '',
        date: moment.utc().format("YYYY-MM-DD HH:mm"),
        airport_id: '',
        city_id: '',
        active: true
      }
    }
  },
  created() {
    if ( this.active ) {
      this.getTrackingStatuses();
      this.fetchTracking();
    }
  },
  watch: {
    'active'(val) {
      if ( val ) {
        this.getTrackingStatuses();
        this.fetchTracking();
      }
    },
  },
  methods: {
    saveTrackingStatus() {
      this.form.app_id = this.appId;
      this.$v.form.$touch();

      if ( this.$refs.airportSelect ) {
        if ( !this.$refs.airportSelect.checkValidity() )
          return this.snotifyError('wrongFormMsg');
      }

      if ( this.$v.form.$invalid )
        return this.snotifyError('wrongFormMsg');

      this.axios.post(this.$urls.application.addTrackingStatus.url.replace(':id', this.appId), this.form)
          .then(response => {
            this.snotifySuccess('operationSuccess');
            this.closeModal();
            this.resetForm();
            this.fetchTracking();
          }).catch(() => {
      });

    },
    fetchTracking() {
      this.axios.get(this.$urls.application.tracking.url.replace(':id', this.appId))
          .then(response => {
            this.tracking = response.data.data;
          }).catch(() => {
      });
    },
    resetForm() {

      this.$v.form.$reset();

      this.form = {
        id: null,
        status_id: '',
        app_id: this.appId,
        comment: '',
        date: moment.utc().format("YYYY-MM-DD HH:mm"),
        airport_id: '',
        city_id: '',
        active: true
      };
    },
    closeModal() {
      this.showModal = false;
      this.resetForm();
    },
    openModal() {
      this.showModal = true;
      this.resetForm();
    },
    getTrackingStatuses() {
      this.axios.get(this.$urls.application.getTrackingStatuses.url)
          .then(response => {
            this.statuses = response.data.data;
          }).catch(() => {
      });
    }
  },
  validations: {
    form: {
      status_id: {
        required
      },
      app_id: {
        required
      },
      airport_id: {
        required
      },
      date: {
        required
      },
    }
  }
}
</script>

<style scoped>

</style>